import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Article, AssetArticle } from 'types'
import { NavTabs, useSetMobileDevice } from 'web-sdk'
import Image from 'next/image'
import classnames from 'classnames'
import { HandThumbDownIcon, HandThumbUpIcon, HeartIcon, RocketLaunchIcon } from '@heroicons/react/24/solid'
import { formatDate, formatDateTime } from 'helpers'
import { useTranslation } from 'next-i18next'
import CustomLink from 'components/CustomLink'
import { getArticleLink } from 'helpers/getArticleLink'
import { getAssetArticleLink } from 'helpers/getAssetArticleLink'

interface HighlightArticlesProps {
  articles: HighlightArticleItem[]
  isMobile: Boolean
  isTablet: Boolean
}

enum ArticleTabs {
  LatestArticles = 'latest articles',
  Featured = 'featured',
  Popular = 'popular',
  CompanyBlog = 'company blog'
}

export type HighlightArticleItemType = 'article' | 'assetArticle'
export interface HighlightArticleItem {
  type: HighlightArticleItemType
  article: Article | AssetArticle
}

const MAX_ARTICLE_LIST = 5

export const HighlightArticles: React.FC<HighlightArticlesProps> = ({ isMobile, isTablet, articles }: HighlightArticlesProps) => {
  const [selectedTab, setSelectedTab] = useState<ArticleTabs>(ArticleTabs.LatestArticles)
  const [selectedArticle, setSelectedArticle] = useState<HighlightArticleItem | null>(articles[0] || null)
  const [articleList, setArticleList] = useState<HighlightArticleItem[]>(
    articles
    .sort((a,b) => new Date(b.article.published_at || '').getTime() - new Date(a.article.published_at || '').getTime())
    .slice(0, MAX_ARTICLE_LIST)
  )

  const { t } = useTranslation('common')

  const tabs = [
    {
      name: t('page.landing.articles.tabs.latestArticles'),
      isCurrentTab: selectedTab === ArticleTabs.LatestArticles,
    },
    {
      name: t('page.landing.articles.tabs.featured'),
      isCurrentTab: selectedTab === ArticleTabs.Featured,
    },
    {
      name: t('page.landing.articles.tabs.popular'),
      isCurrentTab: selectedTab === ArticleTabs.Popular,
    },
    {
      name: t('page.landing.articles.tabs.companyBlog'),
      isCurrentTab: selectedTab === ArticleTabs.CompanyBlog,
    },
  ]

  useEffect(() => {
    let articleItems = []

    switch (selectedTab) {
      case ArticleTabs.LatestArticles:
        articleItems = articles.sort((a,b) => new Date(b.article.published_at || '').getTime() - new Date(a.article.published_at || '').getTime()).slice(0, MAX_ARTICLE_LIST)
        setArticleList(articleItems)
        setSelectedArticle(articleItems[0])
        break
      case ArticleTabs.Featured:
        // TODO: implement filter/sorting by Featured
        articleItems = articles.sort((a,b) => a.article.title.toLowerCase().localeCompare(b.article.title.toLowerCase())).slice(0, MAX_ARTICLE_LIST)
        setArticleList(articleItems)
        setSelectedArticle(articleItems[0])
        break
      case ArticleTabs.Popular:
        // TODO: implement filter/sorting by Popular
        articleItems = articles.sort((a,b) => b.article.title.toLowerCase().localeCompare(a.article.title.toLowerCase())).slice(0, MAX_ARTICLE_LIST)
        setArticleList(articleItems)
        setSelectedArticle(articleItems[0])
        break
      case ArticleTabs.CompanyBlog:
        // TODO: implement filter/sorting by CompanyBlog
        articleItems = articles.sort((a,b) => a.article.description.toLowerCase().localeCompare(b.article.description.toLowerCase())).slice(0, MAX_ARTICLE_LIST)
        setArticleList(articleItems)
        setSelectedArticle(articleItems[0])
        break
    }
  }, [articles, selectedTab])

  const handleSetTab = useCallback((route: string) => {
    setSelectedTab(route as ArticleTabs)
  }, [])

  const renderArticleList = useMemo(() => {
    return (
      <>
        {articleList.map((article, index) => {
          const cnArticle = classnames('rounded p-2 my-1 text-base font-semibold', {
            'bg-text-color-10 border-l-primary-cta-color-60 border-l-4': article.article.id === selectedArticle?.article.id && article.type === selectedArticle?.type,
          })

          return (
            <div key={index} className={cnArticle} onMouseEnter={() => setSelectedArticle(article)}>
              {article.type === 'article' ?
                <CustomLink href={getArticleLink(article.article)}>
                  <a className="line-clamp-2">{article.article.title}</a>
                </CustomLink> :
                <CustomLink href={getAssetArticleLink(article.article as AssetArticle)}>
                  <a className="line-clamp-2">{article.article.title}</a>
                </CustomLink>
              }
              <div className="flex justify-between">
                <div className="flex gap-2">
                  <div className="text-text-color-60">{formatDateTime(article.article.published_at)}</div>
                  {/* <div className="flex gap-1"><RocketLaunchIcon width={18} /> 57%</div>
                  <div className="flex gap-1"><HandThumbDownIcon width={18} /> 43%</div> */}
                </div>
                {/* <div className="flex gap-1 text-xs text-text-color-60 items-center">
                  <div className='flex'>
                    <HandThumbUpIcon width={16}  />
                    <HeartIcon width={16} className='-ml-1 stroke-white' />
                  </div>
                  839
                </div> */}
              </div>
            </div>
          )
        })}
      </>
    )
  }, [articleList, selectedArticle])

  const cnLayout = classnames('w-full border border-divider-color-20 rounded shadow pl-5 py-5 pr-5 mx-[18px] sm:mx-6 lg:mx-0 flex', {
    'flex-col': isTablet
  })

  const cnHighlight = classnames('4xl:w-full 3xl:w-full 2xl:w-[907px] xl:w-[570px]', {
    'ml-5': !isTablet
  })

  const cnTabs = classnames('3xl:max-w-[452px] 2xl:max-w-[452px] xl:max-w-[452px] lg:max-w-[452px] w-full', {
    'mt-5': isTablet && !isMobile,
    'mt-0': isMobile
  })

  return articles.length ? (
    <div className={cnLayout}>
      {!isTablet && <div className={cnTabs}>
        <div className="flex flex-col w-full">
          {/* <div className="mb-5">
            <NavTabs
              tabs={tabs}
              onClick={handleSetTab}
              activeTabClassName="bg-primary-cta-color-60 text-primary-cta-layer-color-60"
              defaultTabClassName="text-neutral-control-layer-color-60 hover:bg-neutral-control-color-30"
              desktopTabsClassName="px-2 py-2 sm:py-0.5 font-metro-bold text-md rounded"
              shouldRenderDropdownMobile={false}
            />
          </div> */}
          {renderArticleList}
        </div>
      </div> || null}
      {!isMobile ? 
        <div className={cnHighlight}>
          <div className="relative flex flex-col">
            <div className='rounded'>
              <Image
                src={selectedArticle!.article.image_url || '/images/empty.svg'}
                alt={t('page.landing.articles.imageAlt', { title: selectedArticle!.article.title })}
                height="1080"
                width="1920"
                className="w-full h-auto rounded-md"
                layout="responsive"
                objectFit="cover"
                loading="eager"
              />
            </div>
            <div className='absolute mt-3 ml-3 px-2 py-0 rounded flex gap-2 items-start'>
              {/* <div className='flex gap-2 px-2 py-2 rounded-lg bg-white bg-opacity-95'>
                <div className='flex gap-1 px-2 py-1 rounded border border-divider-color-10 shadow font-metro-bold text-sm'>
                  <RocketLaunchIcon width={24} />
                  78%
                </div>
                <div className='flex gap-1 px-2 py-1 rounded border border-divider-color-10 shadow font-metro-bold text-sm'>
                  <HandThumbDownIcon width={24} />
                  22%
                </div>
              </div>
              <div className='flex gap-1 px-2 py-2 rounded-lg bg-white bg-opacity-95 text-xs text-text-color-60'>
                <div className='flex'>
                  <HandThumbUpIcon width={16}  />
                  <HeartIcon width={16} className='-ml-1 stroke-white' />
                </div>
                839
              </div> */}

              {/* {selectedArticle?.tags.map((tag, index) => 
                <div key={index} className="bg-main-background-color-opacity/85 px-4 rounded-full text-sm font-semibold text-neutral-control-layer-color-70">
                  {tag.name}
                </div>
              )} */}
            </div>
            <div className='absolute bottom-0 w-full bg-main-background-color-opacity/85 p-5'>
              <h3 className='text-lg font-metro-bold'>{selectedArticle!.article.title}</h3>
              <div>
                {selectedArticle!.article.author && (
                  <>
                    <span className="text-text-color-60">{selectedArticle!.article.author}</span>
                    <span className="text-text-color-60 mx-2">&#8226;</span>
                  </>
                )}
                <span className="text-text-color-60">{formatDateTime(selectedArticle!.article.published_at)}</span>
              </div>    
            </div>
          </div>
        </div>
      : 
        <div className={cnHighlight}>
          <div className="relative flex flex-col">
            <div className='rounded'>
              <Image
                src={selectedArticle!.article.image_url || '/images/empty.svg'}
                alt={t('page.landing.articles.imageAlt', { title: selectedArticle!.article.title })}
                height="1080"
                width="1920"
                className="w-full h-auto rounded-md"
                layout="responsive"
                objectFit="cover"
                loading="eager"
              />
            </div>
          </div>
        </div>
      }
      {isTablet && <div className={cnTabs}>
        <div className="flex flex-col w-full">
          {/* <div className="mb-5">
            <NavTabs
              tabs={tabs}
              onClick={handleSetTab}
              activeTabClassName="bg-primary-cta-color-60 text-primary-cta-layer-color-60"
              defaultTabClassName="text-neutral-control-layer-color-60 hover:bg-neutral-control-color-30"
              desktopTabsClassName="px-2 py-2 sm:py-0.5 font-metro-bold text-md rounded"
              shouldRenderDropdownMobile={false}
            />
          </div> */}
          {renderArticleList}
        </div>
      </div> || null}
    </div>
  ) : null
}
